import styled, { keyframes} from 'styled-components';
import { css } from 'styled-components';
import React from 'react';
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ContactContainer = styled.div`
animation: ${fadeIn} 1s ease-in-out;
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  justify-content: space-evenly;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const InfoColumn = styled.div`
animation: ${fadeIn} 1s ease-in-out;
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const InfoSec = styled.div`
  color: #fff;
  padding: 160px 0;
  background: ${({ lightBg }) => (lightBg ? '#fff' : '#101522')};
`;

export const StyledButton = styled.button`
  background: #0C7500;
  border: 0;
  color: #fff;
  padding: 1em;
  text-transform: uppercase;
  width: 100%;

  &:hover,
  &:focus {
    background: #0C7500;
    color: #fff;
    outline: 0;
    transition: background-color 2s ease-out;
  }
`;

export const FormContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1170px;
  ${(props) =>
    props.wrapper &&
    css`
      box-shadow: 0 0 20px 0 rgba(184, 22, 31, 0.3);
      > * {
        padding: 1em;
      }

      @media (min-width: 700px) {
        display: grid;
        grid-template-columns: 1fr 2fr;
        > * {
          padding: 2em;
        }
      }
    `}
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  font-size: 1.5rem;
`;

export const Input = styled.input`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? '#0467FB' : '#0C7500')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? '#0C7500' : '#0467FB')};
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const Label = styled.label`
  display: block;
`;

export const TextArea = styled.textarea`
  border: 1px solid #e6343b;
  padding: 1em;
  width: 100%;
`;

const Title = ({ className, text }) => <h1 className={className}>{text}</h1>;

export const StyledTitle = styled(Title)`
  color: #b8161f;
  font: 70px;
  text-align: center;
  @media (min-width: 700px) {
    text-align: left;
  }
`;
