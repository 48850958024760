export const homeObjOne = {
  primary: true,
  lightBg: false,
  topLine: 'Hi! 👋🏼 My name is...',
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  headline: 'Brandon Castillo',
  description:
    "I'm a Product Manager based in New York City with a passion for technology and building impactful solutions for users. I believe that technology has the power to solve some of the world's biggest challenges, and I'm committed to using my skills and experience to create products that make a real difference.",
  buttonLabel: 'Contact Me',
  img: require('../../images/headshot.jpeg'),
  alt: 'image',
  start: '',
  imgStart: ''
};

export const homeObjTwo = {
  primary: true,
  lightBg: true,
  topLine: 'My Story',
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  headline: 'Why I do what I do..',
  buttonLabel: 'Contact Me',
  description:
    "I’m driven by a desire to understand user’s needs and build products that solve real-world problems. I’m also a big believer in the power of collaboration, and love working with cross-functional teams to bring new products to life.During my free time, I make it a point to stay up-to-date with the latest technology trends and explore new ways to expand my knowledge. Additionally, I am an avid lover of nature and enjoy exploring the city as well as taking care of my two rescue cats, Miles and Deebo.😸😸",
  img: require('../../images/path.jpg'),
  alt: 'image',
  start: 'true',
  imgStart: 'start'
};

export const homeObjThree = {
  primary: true,
  lightBg: false,
  topLine: 'Full Stack Software Engineer',
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  headline: 'Brandon Castillo',
  description:
    'Hi, thank you for visiting my page. Please feel free to poke around. This website was built using styled components and react js. I hope you like it! Other projects are hosted here as well.',
  buttonLabel: 'Contact Me',
  img: require('../../images/headshot.jpeg'),
  alt: 'image',
  start: '',
  imgStart: ''
};
