export const projectObjOne = {
  primary: true,
  lightBg: true,
  topLine: '',
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  headline: 'JetPet',
  buttonLabel: 'Live Demo Coming Soon',
  description:
    'This is a passion project of mine and something I have always wanted to do! JetPet is an online marketplace that connects pet parents with pet service providers in their area and allows pet parents to safely store their pet’s information.  ',
  img: require('../../images/jetpet.png'),
  alt: 'image',
  start: 'true',
  imgStart: 'start'
};

export const projectObjTwo = {
  primary: true,
  lightBg: false,
  topLine: '',
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  headline: 'CASA Volunteer Tracker',
  buttonLabel: 'Live Demo Coming Soon',
  githubButton: 'GitHub',
  description:
  'Court Appointed Special Advocate® (CASA) and guardian ad litem (GAL) volunteers are appointed by judges to advocate for children’s best interests. This best-interest advocacy makes a life-changing difference for children and youth who have experienced abuse or neglect, many of whom are in foster care.',
  img: require('../../images/casa.png'),
  alt: 'image',
  start: 'true',
  imgStart: 'start'
};
export const projectObjThree = {
  primary: true,
  lightBg: true,
  topLine: '',
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  headline: 'Ruby For Good',
  buttonLabel: 'Live Demo Coming Soon',
  description:
    'Ruby for Good delivers specialized software development solutions to non-profit organizations to bolster their critical missions, build an inclusive technology community, and provide technology-focused educational opportunities to underserved communities.',
  img: require('../../images/ruby.png'),
  alt: 'image',
  start: 'true',
  imgStart: 'start'
};