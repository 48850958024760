import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Form, Input, ContactContainer } from './Contact.elements';
import { InfoSec } from '../../components/InfoSection/InfoSection.elements';
import { email1, email2, email3 } from '../../emailjs';

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(email1, email2, form.current, email3).then(
      (result) => {
        console.log(result.text);
        alert('Email sent successfully!');
      },
      (error) => {
        console.log(error.text);
      }
    );
    form.current.reset();
  };

  return (
    <InfoSec>
      <ContactContainer>
        <Form ref={form} onSubmit={sendEmail}>
          <label>Name:</label>
          <input type='text' name='name' required maxlength='50' />
          <label>Email:</label>
          <input type='email' name='email' required maxlength='50' />
          <label>Message:</label>
          <textarea
            rows='8'
            name='message'
            required
            minlength='2'
            maxlength='500'
          />
          <Input type='submit' value='Send Email' />
        </Form>
      </ContactContainer>
    </InfoSec>
  );
};

export default ContactUs;
