import React from 'react';
import { projectObjOne, projectObjThree, projectObjTwo } from './Data';
import { InfoSection } from '../../components';

const Home = () => {
  return (
    <>
      <InfoSection {...projectObjOne} />
      <InfoSection {...projectObjTwo} />
      <InfoSection {...projectObjThree} />
    </>
  );
};

export default Home;
