import React from 'react';
import { FaLinkedin, FaGithub } from 'react-icons/fa';
import {
  FooterContainer,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  SocialIcon
} from './Footer.elements';

const Footer = () => {
  return (
    <FooterContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to='/'>
            <SocialIcon />
            Brandon Castillo
          </SocialLogo>
          <WebsiteRights>Brandon Castillo © 2022</WebsiteRights>
          <SocialIcons>
            <SocialIconLink
              href='https://github.com/bcastillo32'
              target='_blank'
              aria-label='Github'
            >
              <FaGithub />
            </SocialIconLink>
            <SocialIconLink
              href='https://www.linkedin.com/in/bcastillo123/'
              target='_blank'
              aria-label='Linkedin'
            >
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
};

export default Footer;
